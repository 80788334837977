import React from "react";
import { files } from "../Files/files";

export default  function Footer() {
  const logo = `${files.logo}`;

  return (
    <footer>
    <div className="vh-25"></div>

      <div className="bg-main-color mb-1" style={{ height: "10px" }}></div>

      <div className="text-center text-white p-4 bg-main-color">
        <img className="mt-3" src={logo} />

        <p className="mt-3 h3 fw-bold">
          The No. 1 Japanese Language College in the Philippines
        </p>
        <div className="row d-flex justify-content-center">
          <div className="col-6 col-md-4 col-lg-2">
            <small>
              <i className="fa-solid fa-envelope"></i>
              <span>EMAIL US AT</span>
              <p>info@mkdph.com</p>
            </small>
          </div>
          <div className="col-6 col-md-4 col-lg-2">
            <small>
              <i className="fa-solid fa-phone"></i>
              <span>CALL US ON</span>
              <p>+63822330013</p>
            </small>
          </div>
          <div className="col-12 col">
            <small>
              <i className="fa-solid fa-location-pin"></i>
              <span>ANGLIONGTO AVENUE, MAMAY ROAD, LANANG</span>
              <p>DAVAO CITY, DAVAO DEL SUR, PHILIPPINES</p>
            </small>
          </div>
        </div>
        <p>© 2023 Mindanao Kokusai Daigaku. All Rights Reserved</p>
      </div>
    </footer>
  );
}


