import { React, useState } from "react";

import { Accordion } from "react-headless-accordion";
import AccordItem from "../Components/AccordItem";
import ContactMessage from "../Components/ContactMessage";
export default function Faqs() {
  return (
    <div>
      <div className="container-fluid mt-5 pt-5">
        <div className="row align-items-center">
          <div className="col text-title-lines "></div>
          <div className="col text-center">
            <h1 className="fw-bold color-title">FAQ’s</h1>
          </div>
          <div className="col text-title-lines"></div>
        </div>
      </div>

      <div className="faq container pb-5 mb-5 w-100">
        <div className="row d-flex justify-content-center">
          <div className="col-12 col-lg-9 mt-5">
            <Accordion className=" px-2">
              <AccordItem
                question="1"
                title="How long does the admission application process take?"
                content="The admission application process takes about one to two months, with applications starting in
                June. Applicants are highly encouraged to register as early as possible to meet the requirements for
                admission."
              />
              <AccordItem
                question="2"
                title="I’m from the province. Are Davao applicants given priority over provincial and international applicants?"
                content="No. Both undergo the same admissions process."
              />
              <AccordItem
                question="3"
                title="Is it necessary for me to learn how to speak Japanese?"
                content="All courses, regardless of specialization, include learning the Japanese language and culture. The school has hired Filipino and Japanese nationals to teach the language. Our Filipino teachers who are teaching the Japanese language are passers of the Japanese Language Proficiency Test (JLPT), and the existing Japanese nationals are qualified to teach the “Kokugo” (national language) to Japanese students."
              />
              <AccordItem
                question="4"
                title="As a host institution, do you reissue lost or damaged JLPT certificates?"
                content="Reissuing JLPT certificates is not within our jurisdiction as a host institution. If you want to obtain another score report, we can provide you with the link where you can submit your request. Reissuance is free of charge. Please make sure that you apply well in advance."
              />
              <AccordItem
                question="5"
                title="Can I enroll even if I am not Filipino, Nikkeijin, or Japanese?"
                content="Yes, we have several nationalities enrolled in the school. You can definitely enroll regardless of your nationality. In fact, aside from Filipino and Japanese, we also have Americans, Indians, and Koreans."
              />
            </Accordion>
          </div>
        </div>
      </div>
      <div className="container">
        <div className="row d-flex flex-column justify-content-center align-content-center">
          <div className="col-11 col-lg-8 mb-4">
            <h2 className="fw-bold">Do you have other questions?</h2>
            <h6 className="pt-2">
              You can Email us directly at
              <strong> info@mkdph.com </strong>
              or call us at
              <strong> +63822330013 </strong>.
            </h6>
          <ContactMessage />
          </div>
        </div>
              
      </div>
    </div>
  );
}
