import React from "react";
import { files } from "../Files/files";

export default  function HeroSection({onMoreButtonClick }) {


  const heroImgStyle = {
    backgroundImage: `url(${files.heroBg})`,
  };

  return (
    <div className="hero container-fluid d-flex vh-100 " style={heroImgStyle}>
      <div className="col container-fluid ps-0 ps-md-2 ms-0 ms-md-2 mb-lg-5 pb-lg-5 ms-lg-5 row">
        <div className="col d-flex justify-content-center align-items-center h-100">
          <div className="col ps-lg-1 ms-lg-5 ps-md-2 ms-md-2 mb-5 pb-5 text-center text-md-start">
            <h1
              className="fw-bold"
            >
              The No. 1
            </h1>
            <h1
              className="fw-bold"
            >
              Japanese Language
            </h1>
            <h1
              className="fw-bold mb-4"
            >
              Tertiary Institution in the Philippines
            </h1>
            <a
             onClick={onMoreButtonClick}
              className="btn btn-lg border-primary fs-6 py-3"
            >
              {" "}
              Read More →
            </a>
          </div>
        </div>
      </div>
    </div>
  );
}
