import React from "react";
import { files } from "../Files/files";
  const left_arrow = `${files.left_arrow}`;
  const right_arrow = `${files.right_arrow}`;
export default  function Scholarship() {
  return (
    <div className="container my-5 py-5 px-4 px-md-3">
    <div className="row">
      <div className="col justify-content">
        <h1
          className="fw-bold color-title"
          data-aos="fade-bottom"
          data-aos-duration="500"
        >
          SCHOLARSHIPS
          <span>
            <img
              className="yellow-arrow ms-3 mb-2"
              src={right_arrow}
              alt=""
            />
          </span>
        </h1>
        <h5
          className="fw-bold color-title"
          data-aos="fade-bottom"
          data-aos-delay="200"
          data-aos-duration="500"
        >
          ACADEMIC SCHOLARSHIPS AND GRANT-IN-AID
        </h5>
        <p
          data-aos="fade-bottom"
          data-aos-delay="200"
          data-aos-duration="500"
        >
          The College acknowledges top-performing students every semester
          who are granted discounts on their tuition fees. In order to
          become a Dean's Lister, students must carry at least 18 units
          every semester and obtain the required Weighted Percentage Average
          (WPA). Students who are financially challenged but are excellent
          in academics are also recommended for foster parenting by Japanese
          Nationals.
        </p>
      </div>
      <div className="col-md-1"></div>
      <div
        className="col mt-5"
        data-aos="fade-bottom"
        data-aos-delay="400"
        data-aos-duration="500"
      >
        <h5 className="mx-4 color-title fw-bold">
          Other Scholarships Offered
        </h5>
        <ul className="justify-content">
          <li>Board of Trustees/Employee Tuition Fee Discount</li>
          <li>High School Graduates with Academic Honors</li>
          <li>Academic Scholarship (Dean’s Lister)</li>
          <li>MKD Grant-in-Aid Program</li>
          <li>PNJKIS Alumni Grant</li>
          <li>Japanese Descendant Discount</li>
          <li>MKD KOENKAI Program</li>
        </ul>
      </div>
    </div>
  </div>
  );
}

