import React, { useState, useEffect } from "react";
import Cookies from "js-cookie";

export default function NavigationTop({ onPageChange }) {
  const [activeComponentName, setHighlight] = useState(() => {
    const initialPage = Cookies.get("userPage");
    // console.log(initialPage);
    
    return initialPage ? initialPage : "Homepage";
  });

  const handleLink = (Component) => {
    Cookies.set('userPage', Component, { expires: 7 , sameSite: 'None', secure: true });
    onPageChange(Component);
    setHighlight(Component);
  };

  const handleBeforeUnload = (event) => {
    event.preventDefault();
    window.removeEventListener("beforeunload", handleBeforeUnload);
  };
  useEffect(() => {


    const pageName = activeComponentName;
    let modifiedName = pageName.replace(/Page/g, "");
    if (modifiedName === "Academe") {
      modifiedName = "Academics";
      
    }
    if (modifiedName === "Homepage") {
      modifiedName = "MindanaoKokusaiDaigaku";
    }

    window.history.replaceState(null, "", modifiedName);
  });

  return (
    <>
      <nav
        className="container-fluid navbar navbar-expand-lg navbar-light sticky-top bg-white d-flex  
        text-center py-4 shadow-2"
        id="navBar"
      >
        <div>
          <button
            id="navBtn"
            className="w-100 navbar-toggler"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#navbarNav"
            aria-controls="navbarNav"
            aria-expanded="false"
            aria-label="Toggle navigation"
          ></button>

          <div className="collapse navbar-collapse" id="navbarNav">
            <ul className="navbar-nav d-flex align-items-left container-fluid ">
              <li className="nav-item">
                <a
                  className={`nav-link ${
                    activeComponentName === "Homepage" ? "active" : ""
                  }`}
                  onClick={() => handleLink("Homepage")}
                >
                  HOME
                </a>
              </li>
              <div className="nav-divider vr vr-blurry mx-4 "></div>
              <div className="dropdown">
                <li className="nav-item">
                  <a
                    className={`nav-link ${
                      activeComponentName === "AcademePage" ||
                      activeComponentName === "InternationalStudies" ||
                      activeComponentName === "HumanServices" ||
                      activeComponentName === "Education" ||
                      activeComponentName === "InformationSystem" 
                        ? "active"
                        : ""
                    }`}
                    onClick={() => handleLink("AcademePage")}
                  >
                    ACADEMICS
                  </a>
                </li>
                <div className="dropdown-content academe bg-white p-2 pe-5 rounded-bottom-1">
                  <div className="text-start">
                    <h2 className="nav-link title mt-2">Arts & Humanities </h2>
                    <a
                      className={`nav-link item ${
                        activeComponentName === "InternationalStudies"
                          ? "active"
                          : ""
                      }`}
                      onClick={() => handleLink("InternationalStudies")}
                    >
                      International Studies
                    </a>
                    <a
                      className={`nav-link item ${
                        activeComponentName === "HumanServices" ? "active" : ""
                      }`}
                      onClick={() => handleLink(`HumanServices`)}
                    >
                      Human Services
                    </a>
                    <h2 className="nav-link title mt-2">Teacher Education</h2>
                    <a
                      className={`nav-link item ${
                        activeComponentName === "Education" ? "active" : ""
                      }`}
                      onClick={() => handleLink(`Education`)}
                    >
                      Education
                    </a>
                    <h2 className="nav-link title mt-2">Computing Education</h2>
                    <a
                      className={`nav-link item ${
                        activeComponentName === "InformationSystem"
                          ? "active"
                          : ""
                      }`}
                      onClick={() => handleLink(`InformationSystem`)}
                    >
                      Information Systems
                    </a>
               
                 
                  </div>
                </div>
              </div>
              <div className="nav-divider vr vr-blurry mx-4 "></div>
              <li className="nav-item">
                <a
                  className={`nav-link ${
                    activeComponentName === "AdmissionPage" ? "active" : ""
                  }`}
                  onClick={() => handleLink(`AdmissionPage`)}
                >
                  ADMISSION
                </a>
              </li>
              <div className="nav-divider vr vr-blurry mx-4 "></div>

              <div className="dropdown">
                <li className="nav-item">
                  <a
                    className={`nav-link ${
                      activeComponentName === "StudentServicesPage" ||
                      activeComponentName === "SchoolFacilities" ||
                      activeComponentName === "ClubsOrganizations"
                        ? "active"
                        : ""
                    }`}
                    onClick={() => handleLink("StudentServicesPage")}
                  >
                    STUDENT SERVICES
                  </a>
                </li>
                <div className="dropdown-content services bg-white p-2 pe-5 rounded-bottom-1">
                  <div className="text-start">
                    <a
                      className={`nav-link ${
                        activeComponentName === "StudentServicesPage"
                          ? "active"
                          : ""
                      }`}
                      onClick={() => handleLink("StudentServicesPage")}
                    >
                      Scholarships & Linkages
                    </a>

                    <a
                      className={`nav-link ${
                        activeComponentName === "ClubsOrganizations"
                          ? "active"
                          : ""
                      }`}
                      onClick={() => handleLink("ClubsOrganizations")}
                    >
                      Clubs & Organizations
                    </a>
                    <a
                      className={`nav-link ${
                        activeComponentName === "SchoolFacilities"
                          ? "active"
                          : ""
                      }`}
                      onClick={() => handleLink("SchoolFacilities")}
                    >
                      School Facilities
                    </a>
                  </div>
                </div>
              </div>
              <div className="nav-divider vr vr-blurry mx-4 "></div>
              <div className="dropdown">
                <li className="nav-item">
                  <a
                    className={`nav-link ${
                      activeComponentName === "AboutPage" ||
                      activeComponentName === "BoardOfTrustees" 
                        ? "active"
                        : ""
                    }`}
                    onClick={() => handleLink("AboutPage")}
                  >
                    ABOUT
                  </a>
                </li>
                <div className="dropdown-content about services bg-white p-2 pe-5 rounded-bottom-1">
                  <div className="text-start">
                    <a
                      className={`nav-link ${
                        activeComponentName === "AboutPage" ? "active" : ""
                      }`}
                      onClick={() => handleLink("AboutPage")}
                    >
                      Mindanao Kokusai Daigaku
                    </a>{" "}
                    <a
                      className={`nav-link ${
                        activeComponentName === "BoardOfTrustees"
                          ? "active"
                          : ""
                      }`}
                      onClick={() => handleLink("BoardOfTrustees")}
                    >
                      Board of Trustees
                    </a>{" "}
                  </div>
                </div>
              </div>

              <div className="nav-divider vr vr-blurry mx-4 "></div>
              <div className="dropdown">
                <li className="nav-item">
                  <a
                    className={`nav-link ${
                      activeComponentName === "Milestones" ||
                      activeComponentName === "StudiesAndAwards" ||
                      activeComponentName === "SignificantVisits"
                        ? "active"
                        : ""
                    }`}
                    onClick={() => handleLink(`Milestones`)}
                  >
                    MILESTONES
                  </a>
                </li>
                <div className="dropdown-content milestones bg-white p-2 pe-5 rounded-bottom-1">
                  <div className="text-start">
                    <a
                      className={`nav-link ${
                        activeComponentName === "Milestones" ? "active" : ""
                      }`}
                      onClick={() => handleLink("Milestones")}
                    >
                      Testimonials
                    </a>{" "}
                    <a
                      className={`nav-link ${
                        activeComponentName === "StudiesAndAwards" ? "active" : ""
                      }`}
                      onClick={() => handleLink("StudiesAndAwards")}
                    >
                      Student Achievements
                    </a>{" "}
                    <a
                      className={`nav-link ${
                        activeComponentName === "SignificantVisits"
                          ? "active"
                          : ""
                      }`}
                      onClick={() => handleLink("SignificantVisits")}
                    >
                      Significant Visits
                    </a>
                  </div>
                </div>
              </div>
              <div className="nav-divider vr vr-blurry mx-4 "></div>
              <li className="nav-item">
                <a
                  className={`nav-link ${
                    activeComponentName === "JLPT" ? "active" : ""
                  }`}
                  onClick={() => handleLink(`JLPT`)}
                >
                  JLPT
                </a>
              </li>
              <div className="nav-divider vr vr-blurry mx-4 "></div>
              <li className="nav-item">
                <a
                  className={`nav-link ${
                    activeComponentName === "Contact" ? "active" : ""
                  }`}
                  onClick={() => handleLink(`Contact`)}
                >
                  CONTACT
                </a>
              </li>
            </ul>
          </div>
        </div>
      </nav>
    </>
  );
}
