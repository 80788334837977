import React from "react";
import { files } from "../Files/files";
import Slider from "../Components/Slider";

export default function NewsFeed() {

  const right_arrow = `${files.right_arrow}`;

  return (
    <div>
      <div className="container">
        <h1 className="fw-bold color-title ms-4 ms-lg-2 ">
          RECENT EVENTS
          <span>
            <img className="yellow-arrow ms-3 pb-2" src={right_arrow} alt="" />
          </span>
        </h1>
      </div>
      <div className="container p-0 ">
        <div className="row ">
          <br />
        </div>

        <div className="container mt-3 pt-3">

        <div className="row mt-3 d-flex justify-content-center">  
            <div
              className="col-lg-5 p-0 m-3 card"
              data-aos="zoom-in"
              data-aos-delay="350"
              data-aos-duration="500"
            >
              <div className="card text-white border-0 w-100 h-100 ">
                <img src={files.RibbonCutting} className="card-img" alt="" />
              </div>
              <div className="overlay-text d-flex flex-column justify-content-end">
                <h5 className="card-title fw-bold">
                Ribbon-Cutting and Turnover Ceremony 
                </h5>
                <p className="card-text">
                The former MKD Sky Gym, now christened the MKD Auditorium, is finally open for use by our cherished students and the wider community.
                </p>
              </div>
            </div>
            <div
              className="col-lg-5 p-0 m-3 card"
              data-aos="zoom-in"
              data-aos-delay="400"
              data-aos-duration="500"
            >
              <div className="overlay-text d-flex flex-column justify-content-end">
                <h5 className="card-title fw-bold">Intramurals 2023</h5>
                <p className="card-text">
                  A three-day competition to mark the return of in-person sports
                  at MKD, testing the students' probing minds, physical
                  endurance, and indomitable spirit.
                </p>
              </div>
              <div className="card text-white border-0 w-100 h-100 ">
                <img src={files.Intramurals2023} className="card-img" alt="" />
              </div>
            </div>
          </div>





          <div className="row mt-3 d-flex justify-content-center text-shadow">
            <div
              className="col-lg-5 p-0 m-3 card news-text"
              data-aos="zoom-in"
              data-aos-delay="250"
              data-aos-duration="500"
            >
              <div className="card text-white border-0 w-100 h-100 ">
                <img src={files.Anniversary} className="card-img" alt="" />
              </div>
              <div className="overlay-text d-flex flex-column justify-content-end">
                <h5 className="card-title fw-bold">
                  {" "}
                  One Heart. One Community. One MKD.
                </h5>
                <p className="card-text">
                  20 Years of Excellence. 20 Years of Quality Education.
                  Celebrating 20 Strong Years of Mindanao Kokusai Daigaku.
                </p>
              </div>
            </div>
            <div
              className="col-lg-5 p-0 m-3 card"
              data-aos="zoom-in"
              data-aos-delay="300"
              data-aos-duration="500"
            >
              <div className="overlay-text d-flex flex-column justify-content-end">
                <h5 className="card-title fw-bold">
                入学式 2023
                </h5>
                <p className="card-text">
                Embracing New Beginnings and Endless Possibilities
                </p>
              </div>
              <div className="card text-white border-0 w-100 h-100 ">
                <img src={files.EmbracingNewBeginnings} className="card-img" alt="" />
              </div>
            </div>
          </div>

          {/* <div className="row mt-3 d-flex justify-content-center">
            <div
              className="col-lg-5 p-0 m-3 card"
              data-aos="zoom-in"
              data-aos-delay="350"
              data-aos-duration="500"
            >
              <div className="card text-white border-0 w-100 h-100 ">
                <img src={files.Yamato2023} className="card-img" alt="" />
              </div>
              <div className="overlay-text d-flex flex-column justify-content-end">
                <h5 className="card-title fw-bold">
                  YAMATO The Drummers of Japan Live
                </h5>
                <p className="card-text">
                  Feel the heart-pumping rhythms and electrifying energy of
                  YAMATO, the world-renowned traditional Wadaiko drum group from
                  Japan.
                </p>
              </div>
            </div>
            <div
              className="col-lg-5 p-0 m-3 card"
              data-aos="zoom-in"
              data-aos-delay="400"
              data-aos-duration="500"
            >
              <div className="overlay-text d-flex flex-column justify-content-end">
                <h5 className="card-title fw-bold">Intramurals 2023</h5>
                <p className="card-text">
                  A three-day competition to mark the return of in-person sports
                  at MKD, testing the students' probing minds, physical
                  endurance, and indomitable spirit.
                </p>
              </div>
              <div className="card text-white border-0 w-100 h-100 ">
                <img src={files.Intramurals2023} className="card-img" alt="" />
              </div>
            </div>
          </div> */}

          <div className="row mt-3 d-flex justify-content-center">
            {/* <div
              className="col-lg-5 p-0 m-3 card news-text"
              data-aos="zoom-in"
              data-aos-delay="250"
              data-aos-duration="500"
            >
              <div className="card text-white border-0 w-100 h-100 ">
                <img src={files.Let2023} className="card-img" alt="" />
              </div>
              <div className="overlay-text d-flex flex-column justify-content-end">
                <h5 className="card-title fw-bold"> LET 2023 Passers</h5>
                <p className="card-text">
                  Congratulations to our MKD graduates who have triumphed in the
                  Licensure Examination for Teachers.
                </p>
              </div>
            </div> */}
            {/* <div
              className="col-lg-5 p-0 m-3 card news-text "
              data-aos="zoom-in"
              data-aos-delay="250"
              data-aos-duration="500"
            >
              <div className="card text-white border-0 w-100 h-100 w-100 h-100">
                <img
                  src={files.ContractSigningCeremony}
                  className="card-img"
                  alt=""
                />
              </div>
              <div className="overlay-text d-flex flex-column justify-content-end">
                <h5 className="card-title fw-bold">
                  Contract Signing Ceremony
                </h5>
                <p className="card-text">
                  For the Project for Improvement of the Hall in MKD through the
                  Grant Assistance for Cultural Grassroots Project of the
                  Government of Japan.
                </p>
              </div>
            </div> */}
          </div>

        </div>
      </div>
    </div>
  );
}
