


import React from "react";

export default function Title(props) {
  return (
    <>
      <div className="row align-items-center mt-5 mb-5 px-0 mx-0">
        <div className="col text-title-lines "></div>
        <div className="col text-center">
          <h1 className="fw-bold color-title">{props.title}</h1>
        </div>
        <div className="col text-title-lines"></div>
      </div>
    </>
  );
}
