import heroBg from "./images/hero_bg.jpg";
import admission from "./images/admission_bg.jpg";
import logo from "./images/logo.png";
import IntlStudies from "./images/academics-1.png";
import Hums from "./images/academics-2.png";
import Educ from "./images/academics-3.png";
import InfoSys from "./images/academics-4.png";
import Entrep from "./images/academics-5.png";
import right_arrow from "./images/right_arrow.png";
import left_arrow from "./images/left_arrow.png";
import events1 from "./images/events1.jpg";
import events2 from "./images/events2.jpg";
import events3 from "./images/events3.jpg";
import events4 from "./images/events4.jpg";
import carousel_1 from "./images/carousel_1.jpg";
import carousel_2 from "./images/carousel_2.jpg";
import admission_bg from "./images/admission_bg.jpg";
import abouthero from "./images/abouthero.jpg";
import kokusai_meaning from "./images/kokusai_meaning.png";
import mr_tatsuo from "./images/MR-TATSUO-UCHIDA.jpg";
import mrs_ayako from "./images/mrs-ayako-uchida.jpg";
import rev_matsaka from "./images/REV-MASATAKA-AJIRO.jpg";
import hymn from "./videos/mkdhymn.mp3";
import IntlStudiesBg from "./images/intlstudies_bg.jpg";
import img_holder_1 from "./images/img_holder_1.jpg";
import img_holder_2 from "./images/img_holder_2.jpg";
import img_holder_3 from "./images/img_holder_3.jpg";
import img_holder_4 from "./images/img_holder_4.jpg";
import escovilla from "./images/escovilla.jpg";
import cespon from "./images/cespon.jpg";
import apigo from "./images/apigo.jpg";
import mallari from "./images/mallari.jpg";
import iwamoto from "./images/iwamoto.jpg";
import suemitsu from "./images/suemitsu.jpg";
import vitto from "./images/vitto.jpg";
import obenza from "./images/obenza.jpg";
import laurezo from "./images/laurezo.jpg";
import mallarilarge from "./images/mallarilarge.jpg";
import Duterte from "./images/Duterte.jpg";
import Koshikawa from "./images/Koshikawa.jpg";
import Capuno from "./images/Capuno.jpg";
import Ezell from "./images/Ezell.jpg";
import Ocdenaria from "./images/Ocdenaria.jpg";
import Manalac from "./images/Manalac.jpg";
import Honjo from "./images/Honjo.jpg";
import Endo from "./images/Endo.jpg";
import Balonquit from "./images/Balonquit.jpg";
import Bayon from "./images/Bayon.jpg";
import cadiogan from "./images/cadiogan.jpg";
import Yamamoto from "./images/Yamamoto.jpg";
import Urabe from "./images/Urabe.jpg";
import TrainingSquadron from "./images/TrainingSquadron.jpg";
import Shimoji from "./images/Shimoji.jpg";
import Koshikawa2 from "./images/Koshikawa2.jpg";
import KonoLocsin from "./images/KonoLocsin.jpg";
import Katsura from "./images/Katsura.jpg";
import Ishikawa from "./images/Ishikawa.png";
import Haneda from "./images/Haneda.jpg";
import Abe2 from "./images/Abe2.jpg";
import Abe1 from "./images/Abe1.jpg";
import nathalia from "./images/nathalia.jpg";
import gerald from "./images/gerald.jpg";
import cespon_2 from "./images/cespon_2.jpg";
import gracia from "./images/gracia.jpg";
import bernie from "./images/bernie.jpg";
import machida from "./images/machida.jpg";
import rowena from "./images/rowena.jpg";
import menuGif from "./images/menu.gif";
import EducLocalCouncil from "./images/EducLocalCouncil.jpeg";
import GakuseiJichikai from "./images/GakuseiJichikai.jpg";
import HumanServicesLocalCouncil from "./images/HumanServicesLocalCouncil.png";
import IchibanEnsemble from "./images/IchibanEnsemble.JPG";
import IISC from "./images/IISC.jpg";
import ISShogunate from "./images/ISShogunate.jpg";
import Kaugmaon from "./images/Kaugmaon.JPG";
import Kotobuki from "./images/Kotobuki.jpg";
import NihongoPodcastSociety from "./images/NihongoPodcastSociety.jpg";
import NSTP from "./images/NSTP.jpg";
import PeerFacilitators from "./images/PeerFacilitators.jpg";
import RCYCouncil from "./images/RCYCouncil.jpg";
import Tsuushin from "./images/Tsuushin.png";
import SHIOMURA from "./images/SHIOMURA.jpg";

import EmbracingNewBeginnings from "./images/EmbracingNewBeginnings.jpg";

import JLPT_page from "./images/JLPT_page.png";



import Let2023 from "./images/Let2023.png";
import RibbonCutting from "./images/RibbonCutting.jpg";
import Intramurals2023 from "./images/Intramurals2023.jpg";
import Anniversary from "./images/20thAnniversary.jpg";
import Graduation from "./images/Graduuation.jpg";
import ContractSigningCeremony from "./images/ContractSigningCeremony.jpg";

import BAISBanner from "./images/1.png";
import BHSBanner from "./images/2.png";
import EDUCBanner from "./images/3.png";
import BISBanner from "./images/4.png";


import JLPTA from "./images/JLPTA.png";
import JLPTB from "./images/JLPTB.jpg";

import INTERNATIONALSTUDIES1 from "./images/INTERNATIONALSTUDIES1.jpg";
import INTERNATIONALSTUDIES2 from "./images/INTERNATIONALSTUDIES2.jpg";
import INTERNATIONALSTUDIESa from "./images/INTERNATIONALSTUDIESa.jpg";
import INTERNATIONALSTUDIESb from "./images/INTERNATIONALSTUDIESb.jpg";
import INTERNATIONALSTUDIESc from "./images/INTERNATIONALSTUDIESc.jpg";

import HUMANSERVICES1 from "./images/HUMANSERVICES1.jpg";
import HUMANSERVICES2 from "./images/HUMANSERVICES2.jpg";
import HUMANSERVICESa from "./images/HUMANSERVICESa.jpg";
import HUMANSERVICESb from "./images/HUMANSERVICESb.jpg";
import HUMANSERVICESc from "./images/HUMANSERVICESc.jpg";

import EDUCATION1 from "./images/EDUCATION1.jpg";
import EDUCATION2 from "./images/EDUCATION2.jpg";
import EDUCATIONa from "./images/EDUCATIONa.jpg";
import EDUCATIONb from "./images/EDUCATIONb.jpg";

import ENTREPRENEURSHIP1 from "./images/ENTREPRENEURSHIP1.jpg";
import ENTREPRENEURSHIP2 from "./images/ENTREPRENEURSHIP2.jpg";
import ENTREPRENEURSHIPa from "./images/ENTREPRENEURSHIPa.jpg";
import ENTREPRENEURSHIPb from "./images/ENTREPRENEURSHIPb.jpg";

import INFORMATIONSYSTEMS1 from "./images/INFORMATIONSYSTEMS1.jpg";
import INFORMATIONSYSTEMS2 from "./images/INFORMATIONSYSTEMS2.jpg";
import INFORMATIONSYSTEMSa from "./images/INFORMATIONSYSTEMSa.jpg";
import INFORMATIONSYSTEMSb from "./images/INFORMATIONSYSTEMSb.jpg";
import INFORMATIONSYSTEMSc from "./images/INFORMATIONSYSTEMSc.jpg";

export const files = {
  menuGif,
  heroBg,
  JLPT_page,
  logo,
  admission,
  IntlStudies,
  Hums,
  Educ,
  InfoSys,
  Entrep,
  right_arrow,
  events1,
  events2,
  events3,
  events4,
  carousel_1,
  carousel_2,
  admission_bg,
  left_arrow,
  abouthero,
  kokusai_meaning,
  mr_tatsuo,
  mrs_ayako,
  rev_matsaka,
  IntlStudiesBg,
  hymn,
  img_holder_1,
  img_holder_2,
  img_holder_3,
  img_holder_4,
  escovilla,
  cespon,
  apigo,
  mallari,
  iwamoto,
  suemitsu,
  vitto,
  obenza,
  laurezo,
  mallarilarge,
  Duterte,
  Koshikawa,
  Capuno,
  Ezell,
  Ocdenaria,
  Manalac,
  Honjo,
  Endo,
  Balonquit,
  Bayon,
  Yamamoto,
  Urabe,
  TrainingSquadron,
  Shimoji,
  Koshikawa2,
  KonoLocsin,
  Katsura,
  Ishikawa,
  Haneda,
  Abe2,
  Abe1,
  cadiogan,
  nathalia,
  gerald,
  cespon_2,
  gracia,
  bernie,
  machida,
  rowena,
  EducLocalCouncil,
  GakuseiJichikai,
  HumanServicesLocalCouncil,
  IchibanEnsemble,
  IISC,
  ISShogunate,
  Kaugmaon,
  Kotobuki,
  NihongoPodcastSociety,
  NSTP,
  PeerFacilitators,
  RCYCouncil,
  Tsuushin,
  INTERNATIONALSTUDIES1,
  INTERNATIONALSTUDIES2,
  INTERNATIONALSTUDIESa,
  INTERNATIONALSTUDIESb,
  INTERNATIONALSTUDIESc,
  HUMANSERVICES1,
  HUMANSERVICES2,
  HUMANSERVICESa,
  HUMANSERVICESb,
  HUMANSERVICESc,
  ENTREPRENEURSHIP1,
  ENTREPRENEURSHIP2,
  ENTREPRENEURSHIPa,
  ENTREPRENEURSHIPb,
  INFORMATIONSYSTEMS1,
  INFORMATIONSYSTEMS2,
  INFORMATIONSYSTEMSa,
  INFORMATIONSYSTEMSb,
  INFORMATIONSYSTEMSc,
  EDUCATION1,
  EDUCATION2,
  EDUCATIONa,
  EDUCATIONb,
  Let2023,
  RibbonCutting,
  Intramurals2023,
  Anniversary,
  Graduation,
  ContractSigningCeremony,
  JLPTA,
  JLPTB,
  BAISBanner,
  BHSBanner,
  EDUCBanner,
  BISBanner,
  SHIOMURA,
  EmbracingNewBeginnings
};
