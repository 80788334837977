import React from "react";
import { files } from "../Files/files";
import Carousel from "react-bootstrap/Carousel";

export default function Slider(props) {
  // const imageCount = props.sliderImages.length;

  // const RenderSlides = (props) => {
  //   const sliders = [];
  //   for (let i = 0; i < imageCount; i++) {
  //     sliders.push(SliderContent(props, i));
  //   }
  //   return sliders;
  // };

//   const SliderContent = (props, i) => {
//     return (
// <div>
// <img src="props" alt="" />

// </div>
      
//       // <Carousel.Item key={i}>
      //   <div className="acads-carousel-container">
      //     <img
      //       className="acads-carousel-img"
      //       src={props.sliderImages[i]}
      //       alt={`slide${i}`}
      //     />
      //   </div>
      //   <Carousel.Caption>
      //     <div className="text-shadow ">
           
      //     </div>
      //   </Carousel.Caption>
      // </Carousel.Item>
  //   );
  // };

  return (
    <div className="container-fluid">
   <img src={props} alt="" />
    </div>
  );
}
