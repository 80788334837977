import React from "react";
import { files } from "../Files/files";

export default function Header() {
  const logo = `${files.logo}`;

  return (
    <div className="container-fluid bg-white index-top">
      <header className="container py-4">
        <div className="d-flex flex-wrap align-items-center justify-content-center justify-content-lg-start">
          <div className="col-lg-1 me-3 me-lg-5">
            <img src={logo} id="headingLogo" className="" />
          </div>
          <div className="col col-lg-7">
            <h3 className="ps-md-4 ps-lg-5 text-center text-lg-start fs-title fs-title-jp">
              ミンダナオ国際大学
            </h3>
            <h1 className="ps-md-4 ps-lg-5 text-center text-lg-start fs-title">
              <span className="firstLetter fw-bold">M</span>indanao
              <span className="firstLetter fw-bold  ms-2 ms-lg-4">K</span>okusai
              <span className="firstLetter fw-bold ms-2 ms-lg-4">D</span>aigaku
            </h1>
            <h6 id="Tagline" className="ps-md-4 ps-lg-5 text-center text-lg-start fs-tagline">
              The No. 1 Japanese Language Tertiary Institution in the
              Philippines
            </h6>
          </div>
        </div>
      </header>
    </div>
  );
}
