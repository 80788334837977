import React from "react";
import { files } from "../Files/files";
import Title from "../Components/Title";

export default function Academe() {
  const IntlStudies = `${files.IntlStudies}`;
  const Hums = `${files.Hums}`;
  const Educ = `${files.Educ}`;
  const InfoSys = `${files.InfoSys}`;
  const Entrep = `${files.Entrep}`;
  const mallari = `${files.mallari}`;

  return (
    <div className="mt-5 pt-5">
      <div className="container-fluid">
        <Title title="MESSAGE FROM THE PRESIDENT" />
      </div>

      <div className="container my-5">
        <div className="row">
          <div className="col-12 col-lg-4 president-image-container p-0">
            <img className="president-image" src={mallari} alt="" />
          </div>

          <div className="col-12 col-lg-7 d-flex align-items-center position-relative mt-5 mt-lg-0">
            <div className="position-absolute top-0">
              <h1 className="big-quote gloock mt-lg-2 ms-md-4">“</h1>
            </div>
            <div>
              <h2 className="fw-bold ps-2 ps-lg-5 mb-5">
                INES P. MALLARI, J.D., Ph.D.
              </h2>
              <h5 className="ps-2 ps-lg-5 mt-5 mt-md-0 justify-content-sm">
                <strong>Mindanao Kokusai Daigaku (MKD) </strong> – the only
                college in the country run by Japanese descendants – prides
                itself as a strong bridge between Japan and the Philippines
                <br />
                <br />
                <strong> Our institution </strong>is regulated by the Commission
                on Higher Education (CHED), following the Philippine educational
                system while placing a strong emphasis on Japanese culture.
              </h5>
            </div>
          </div>
        </div>
        <div className="row ps-2 ps-md-0 mt-5 pt-0 pt-md-5 mt-md-0 justify-content-sm">
          <h5>
            <strong> Our students</strong> have a deep appreciation and
            understanding of the language and culture of Japan.
            <br />
            <br />
            <strong> Our graduates</strong> are proficient in Nihongo, and are
            sought for by many Japanese multinational companies and other
            prestigious offices and agencies in the Philippines and Japan.
            <br />
            <br />
            <strong> The COVID-19 pandemic</strong> upended the world as we knew
            it and prompted us to reflect and reassess our status quo. It
            brought about significant changes to our way of life, including our
            teaching modality which was re-engineered from face to face learning
            to distance education. With God’s grace and the help of
            technological innovations, we have been able to continue delivering
            quality education at MKD, whether online or face to face.
            <br />
            <br />
            <strong> As we continue to live the “new normal”</strong>, our focus
            at MKD is enhancing our distinctive programs and offerings. With our
            rapidly changing world, we are dedicated now more than ever to
            better equip our students with necessary knowledge and practical
            skills to hone them into becoming leaders of our community and
            well-rounded citizens of the world.
          </h5>
        </div>
      </div>
      <div id="academeLogos" className="container my-5 py-5">
        <div className="color-title row text-center">
          <div
            className="col-sm"
            data-aos="fade-down"
            data-aos-delay="200"
            data-aos-duration="500"
          >
            <img src={IntlStudies} />
            <p className="fw-bold">International Studies Department</p>
          </div>
          <div
            className="col-sm"
            data-aos="fade-down"
            data-aos-delay="200"
            data-aos-duration="500"
          >
            <img src={Hums} />
            <p className="fw-bold">Human Services Department</p>
          </div>
          <div
            className="col-sm"
            data-aos="fade-down"
            data-aos-delay="200"
            data-aos-duration="500"
          >
            <img src={Educ} />
            <p className="fw-bold">Education Department</p>
          </div>
          <div
            className="col-sm"
            data-aos="fade-down"
            data-aos-delay="200"
            data-aos-duration="500"
          >
            <img src={InfoSys} />
            <p className="fw-bold">Information Systems Department</p>
          </div>
        </div>
      </div>
      <hr className="hr" />
    </div>
  );
}
