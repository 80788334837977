import React, { useRef, useState } from "react";
import emailjs from "@emailjs/browser";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

export const ContactMessage = () => {

  const handleNameChange = (e) => {
    setName(e.target.value);
  };

  const handleEmailChange = (e) => {
    setEmail(e.target.value);
  };

  const handleMessageChange = (e) => {
    setMessage(e.target.value);
  };


  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");
  const form = useRef();

 
  const clearInputs = () => {
    setName("");
    setEmail("");
    setMessage("");
  };

  const sendEmail = (e) => {
    const inputEmail = document.getElementById("inputEmail");
    const emailValue = inputEmail.value.trim();
    if (!emailValue.includes("@")) {
      return;
    }
    e.preventDefault();
    toast.promise(
      new Promise((resolve, reject) => {
        setTimeout(() => {
          emailjs
            .sendForm(
              "service_blwpnob",
              "template_rm8h7pg",
              form.current,
              "ZN6xDMUDrSwb1Ogk9"
            )
            .then(
              (result) => {
                resolve();
                console.log(result.text);
                clearInputs();
              },
              (error) => {
                reject(error);
                console.log(error.text);
              }
            );
        }, 2000);
      }),
      {
        pending: "Sending message...",
        success: "Message sent successfully!",
        error: "Failed to send message. Please try again later.",
      },
      {
        autoClose: 5000,
      }
    );
  };

  return (
    <>
      <div className="container">
        <div className="row justify-content-center">
          <div className="col p-0">
            <form ref={form} onSubmit={sendEmail} className="">
              <p className="">
                <strong className="fs-3 ">
                  {" "}
                  You may also send us a message below
                </strong>
              </p>
              <div className="form-group">
                <label className="ps-2">Your Name</label>
                <input
                  type="text"
                  className="form-control"
                  id="inputName"
                  name="from_name"
                  placeholder="Name"
                  value={name}
                  onChange={handleNameChange}
                  required
                />
              </div>
              <div className="form-group mt-4">
                <label className="ps-2 ">Your Email</label>
                <input
                  type="text"
                  className="form-control"
                  id="inputEmail"
                  name="from_email"
                  placeholder="Email"
                  value={email}
                   onChange={handleEmailChange}
                  required
                />
                <small id="emailHelp" className="ps-2 form-text text-muted">
                  We'll never share your email with anyone else.
                </small>
              </div>
              <div className="form-group mt-4">
                <label className="ps-2">Message</label>
                <textarea
                  className="form-control"
                  name="message"
                  id="exampleFormControlTextarea1"
                  rows="3"
                  value={message}
                    onChange={handleMessageChange}

                  required
                ></textarea>
              </div>
              <div className="row">
                <button
                  type="submit"
                  value="Send"
                  className="col mt-4 btn btn-primary col"
                >
                  Send
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
      <div>
        <ToastContainer
          position="bottom-right"
          autoClose={5000}
          hideProgressBar
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss={false}
          draggable
          pauseOnHover
          theme="light"
        />
      </div>
    </>
  );
};

export default ContactMessage;
