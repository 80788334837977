import React from "react";
import { files } from "../Files/files";

export default  function Admission() {
  const admission = {
    backgroundImage: `url(${files.admission})`,
  };
  function mkdFb() {
    window.location.href = 'https://www.facebook.com/mindanaointernationalcollege';
  }
  return (
    <>

    <div
      className="admission mb-5 container-fluid p-md-5 p-5 py-5 parallax text-white "
      style={admission}
    >
      <div className="row d-flex justify-content-end  align-content-center h-100">
        <div className="admission-text col-md-8 col-lg-5 text-end me-lg-3">
          <h1
            className="fw-bold color-title mt-1 mt-md-5"
            data-aos="fade-right"
            data-aos-delay="200"
            data-aos-duration="500"
          >
            <span>
              <img
                className="yellow-arrow me-3 mb-2"
                src="image/left_arrow.png"
                alt=""
              />
            </span>
            ENROLLMENT IS ONGOING
          </h1>
          <hr />
          <h5 className=""
            data-aos="fade-right"
            data-aos-delay="200"
            data-aos-duration="500"
          >
         Please click the link below to apply for admission to A.Y. 2023-2024. <br />
         <a className="fw-bold color-highlight text-shadow fs-5 text-decoration-none" href="https://bit.ly/MKDOnlineEnrollment23-24" target="_blank">Online Enrollment Form</a>
          </h5>
         
          <h5 className=""
            data-aos="fade-right"
            data-aos-delay="200"
            data-aos-duration="500"
          >
            <br />
         For additional info on face-to-face class schedules, please visit the 
         <a className="fw-bold color-highlight text-shadow fs-5 text-decoration-none" href=" https://www.facebook.com/mindanaointernationalcollege" target="_blank"> MKD Facebook page.</a>
          </h5>
        </div>
      </div>
    </div>

      </>
  );
}


