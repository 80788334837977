import React, { useEffect, useState } from "react";
import { files } from "../Files/files";

export default function NavLogo(props) {
  const [showText, setShowText] = useState(false);
  const [showLogo, setShowLogo] = useState(null);
  const logo = `${files.logo}`;

  useEffect(() => {
    function handleScroll() {
      if (window.scrollY > 300) {
        setShowText(true);
      } else {
        setShowText(false);
      }
    }

    if (showText && props.sidebar) {
      setShowLogo(true);
    } else {
      setShowLogo(false);
    }

    window.addEventListener("scroll", handleScroll);

    // Clean up event listener on component unmount
    return () => window.removeEventListener("scroll", handleScroll);
  }, [props.sidebar, showText]);

  return (
    <>
      <div className={`row ps-2 transition ${showLogo ? "bg-main-color" : ""}`}>
        <div className="col">
          <div className="row d-flex ">
            <div className="col d-flex align-items-center py-2 py-md-3 cursor-pointer p-0 m-0">
             
              <p
                className={`nav-logo-text text-white p-0 m-0 ps-2 ${
                  showLogo ? "active" : ""
                }`}
              > <img className="top-nav-logo pe-2 px-md-4" src={logo} alt="" />
                <strong className="color-highlight">M</strong>indanao{" "}
                <strong className="color-highlight">K</strong>okusai{" "}
                <strong className="color-highlight">D</strong>aigaku
              </p>
            </div>
          </div>
        </div>
        <div
          className="col-2 d-flex justify-content-center align-items-center pb-3 cursor-pointer"
          onClick={props.ToggleSidebar}
        >
          <div className={`menu-1 ${props.isActive ? "" : "active"}`}>
            <div className={`h-100 w-100 transition ${showLogo ? "bg-white" : "bg-main-color"}`}></div>
          </div>
          <div className={`menu-2 ${props.isActive ? "" : "active"}`}>
            <div className="bg-secondary-color h-100 w-100"></div>
          </div>{" "}
          <div className={`menu-3 ${props.isActive ? "" : "active"}`}>
          <div className={` h-100 w-100 transition ${showLogo ? "bg-white" : "bg-main-color"}`}></div>
          </div>
        </div>
      </div>
    </>
  );
}
