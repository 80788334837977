import React from "react";

export default function MissionVision() {
  return (
    <div className="container my-5 text-center text-dark d-flex align-items-center">
      <div className="row">
        <div
          className="col-lg-4 p-4 p-lg-4"
          data-aos="fade-down"
          data-aos-delay="50"
          data-aos-duration="500"
        >
          <h2 className="fw-bold color-title ">MISSION</h2>
          <h5 className="fst-italic justify-content">
            <span className="h2 color-highlight fw-bold">W</span>e aim to provide a
            diverse range of learning opportunities and activities in Japanese,
            English, and Filipino languages, as well as other competencies. Our
            goal is to produce quality and relevant research outputs, while also
            promoting social justice and peace through exposure to diverse
            cultures. Additionally, we strive to instill a culture of morality,
            civic consciousness, gender sensitivity, and corporate social
            responsibility in all of our endeavors.
          </h5>
        </div>
        <div
          className="col-lg-4 p-4 p-lg-4"
          data-aos="fade-down"
          data-aos-delay="150"
          data-aos-duration="500"
        >
          <h2 className="fw-bold color-title">PHILOSOPHY</h2>
          <h5 className="fst-italic justify-content">
            <span className="h2 color-highlight fw-bold">T</span>he College is dedicated
            to providing a holistic education, and aims to produce graduates who
            are well-rounded individuals. Our goal is to empower students to
            become active and engaged members of society, equipped to face any
            global problem. To achieve this, we provide quality education and a
            wide range of learning opportunities. We also focus on developing
            the whole person, including their emotional, social, and ethical
            abilities, in order to create graduates who have the knowledge,
            skills, and values to make a positive impact on the world.
          </h5>
        </div>
        <div
          className="col-lg-4 p-4 p-lg-4"
          data-aos="fade-down"
          data-aos-delay="200"
          data-aos-duration="500"
        >
          <h2 className="fw-bold color-title">VISION</h2>
          <h5 className="fst-italic justify-content">
            <span className="h2 color-highlight fw-bold">A</span>n academic community
            proficient in Japanese Language, equipped with multicultural
            sensitivity, strong work ethics, and global competencies which are
            essential in today's globalized world. Students are well-prepared
            for the challenges and opportunities of the 21st century, and will
            be able to make a positive impact in their fields and in the world
            at large.
          </h5>
        </div>
      </div>
    </div>
  );
}
