import React from "react";


export default function MenuDrop(props) {
  return (
    <>
    <span className="ms-2">
        <span
          className={`collapse-icon-1 bg-danger ${
            props.expanded === "true" && props.name === props.activeMenu
              ? "active" : ""
          }`}
        >
          <span className="h-100 w-100 "></span>
        </span>
        <span
          className={`collapse-icon-2 bg-secondary-color ${
            props.expanded === "true" && props.name === props.activeMenu
            ? "active" : ""
        }`}
        >
          <span className="h-100 w-100"></span>
        </span>{" "}
        </span>
    </>
  );
}
