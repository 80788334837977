import HeroSection from "./HeroSection";
import MissionVision from "../About/MissionVision";
import Admission from "../Admission/Admission";
import Faqs from "./Faqs";
import Academe from "../Academics/Academe";
import Scholarship from "../StudentServices/Scholarship";
import NewsFeed from "./NewsFeed";


export default function Homepage({onMoreButtonClick}) {

  return (
    <div className="HomePage">
      {/* <HeroSection /> */}
      <HeroSection onMoreButtonClick={onMoreButtonClick}/>
      <MissionVision />
      <Admission />
      <Academe />
      <Scholarship />
      <NewsFeed />
      <Faqs />
    </div>
  );
} 