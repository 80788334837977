import React from "react";
import "./App.css";
import PageLayout from "./PageLayout";


function App() {
 

  return (
   <PageLayout />
  );
}

export default App;
