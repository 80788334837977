import React, { useState, useEffect, useRef } from "react";
import Cookies from "js-cookie";

import { files } from "../Files/files";
import Homepage from "../Home/Homepage";
import NavLogo from "../Components/NavLogo";
import MenuDrop from "../Components/MenuDrop";

import {
  Accordion,
  AccordionBody,
  AccordionHeader,
  AccordionItem,
} from "react-headless-accordion";

export default function ToggleSidebar({ onPageChange }) {
  const logo = `${files.logo}`;

  const checkRef = useRef(null);
  const refAcad = useRef(null);
  const refServ = useRef(null);
  const refMil = useRef(null);
  const refAbt = useRef(null);

  const [activeComponent, setActiveComponent] = useState(<Homepage />);
  const [activeSidebar, setActiveSidebar] = useState(null);
  const [activeMenu, setactiveMenu] = useState(null);
  const [ariaExpanded, setariaExpanded] = useState(null);

  const [isActive, setIsToggled] = useState(true);
  const ToggleSidebar = () => {
    setIsToggled(!isActive);
  };

  const handleLink = (Component) => {
    setActiveComponent(<Component />);
    onPageChange(Component);
    setIsToggled(!isActive);
    
  };

  useEffect(() => {
    setActiveSidebar(isActive);

    const pageName = activeComponent.type;
    if (typeof pageName === 'string') {
      let modifiedName = pageName.replace(/Page/g, '');
      if (modifiedName === "Homepage") {
        modifiedName = "MindanaoKokusaiDaigaku";
      }
      window.history.replaceState(null, '', modifiedName);
    } else {
      // Handle the case where activeComponent.type is not a string
    }
  }, [isActive, activeMenu]);

  const checkAriaExpanded = (reference, task) => {
    const category = ["ACADEMICS", "STUDENTSERVICES", "MILESTONES", "ABOUT"];
    if (task.current) {
      
    const ariaExpanded = task.current.getAttribute("aria-expanded");
      const parentDiv = task.current.parentNode;
      const ariaExpandedValue = parentDiv.getAttribute("aria-expanded");

      setariaExpanded(ariaExpandedValue);


      if (activeMenu === reference && setariaExpanded) {
        setactiveMenu(null);
      } else {
        setactiveMenu(reference);
      }
    }
  };

  return (
    <>
      <div
        id="block-overlay"
        className={`position-fixed ${activeSidebar ? "" : "active"}`}
        onClick={ToggleSidebar}
      ></div>
      <div id="NavTop" className="w-100 sticky-top ">
        <div className="container-fluid ">
          <NavLogo
            sidebar={activeSidebar}
            isActive={isActive}
            ToggleSidebar={ToggleSidebar}
          />
        </div>

        <div
          className={`d-flex col-12 col-md-2 col-lg-3 flex-column vh-100 ${
            isActive ? "" : "active"
          }`}
          id="sidebar"
        >
          <div className="w-100 ">
            <div className="container-fluid  pt-4">
              <div className="row transition">
                <div className="col">
                  <div className="row d-flex ">
                    <div className="col d-flex align-items-center py-2 py-md-3 cursor-pointer p-0 m-0">
                      <p className="nav-logo-text text-white p-0 m-0 active ps-3 p-md-4">
                        {" "}
                        <img
                          className="top-nav-logo pe-2 px-md-4 mb-1"
                          src={logo}
                          alt=""
                        />
                        <strong className="color-highlight">M</strong>indanao{" "}
                        <strong className="color-highlight">K</strong>okusai{" "}
                        <strong className="color-highlight">D</strong>aigaku
                      </p>
                    </div>
                  </div>
                </div>
                <div
                  className="sidebar-close  col-2 d-flex justify-content-center align-items-center pb-3 pe-5 pt-3 cursor-pointer"
                  onClick={ToggleSidebar}
                >
                  <div className="menu-1 active">
                    <div className="bg-white h-100 w-100"></div>
                  </div>
                  <div className="menu-2 active ">
                    <div className="bg-white w-100 h-100"></div>
                  </div>{" "}
                  <div className="menu-3 active ">
                    <div className="bg-white w-100 h-100"></div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="sideNavLinks mt-4 text-white ps-3">
            <Accordion className="container-fluid">
              <AccordionItem className="row">
                <AccordionHeader className="col-10 btn shadow-0 text-start text-transform-none text-white rounded-0 link-button">
                  <p
                    className={`nav-link link-text ${
                      activeComponent.type.name === "Homepage" ||
                      activeComponent.type === "Homepage"
                        ? "active"
                        : ""
                    }`}
                    onClick={() => handleLink("Homepage")}
                  >
                    HOME
                  </p>
                </AccordionHeader>
              </AccordionItem>
              <br />
              <AccordionItem className="row">
                <AccordionHeader
                  className="col-10 btn shadow-0 text-start text-transform-none text-white rounded-0 link-button"
                  onClick={() => checkAriaExpanded("ACADEMICS", refAcad)}
                >
                  <p
                    ref={refAcad}
                    className={`nav-link link-text ${
                      activeComponent.type === "AcademePage" ||
                      activeComponent.type === "InternationalStudies" ||
                      activeComponent.type === "HumanServices" ||
                      activeComponent.type === "Education" ||
                      activeComponent.type === "InformationSystem"
                        ? "active"
                        : ""
                    }`}
                  >
                    ACADEMICS
                    <MenuDrop
                      name="ACADEMICS"
                      expanded={ariaExpanded}
                      activeMenu={activeMenu}
                    />
                  </p>
                </AccordionHeader>

                <AccordionBody>
                  <div className="link-content ps-4 py-2 col-10">
                    <h2 className="nav-link text-white title pb-2">
                      Arts & Humanities{" "}
                    </h2>
                    <a
                      className={`nav-link item ${
                        activeComponent.type === "InternationalStudies"
                          ? "active"
                          : ""
                      }`}
                      onClick={() => handleLink("InternationalStudies")}
                    >
                      International Studies
                    </a>
                    <a
                      className={`nav-link item ${
                        activeComponent.type === "HumanServices" ? "active" : ""
                      }`}
                      onClick={() => handleLink(`HumanServices`)}
                    >
                      Human Services
                    </a>
                    <h2 className="nav-link text-white title pb-2">
                      Teacher Education
                    </h2>
                    <a
                      className={`nav-link item ${
                        activeComponent.type === "Education" ? "active" : ""
                      }`}
                      onClick={() => handleLink(`Education`)}
                    >
                      Education
                    </a>
                    <h2 className="nav-link text-white title pb-2">
                      Computing Education
                    </h2>
                    <a
                      className={`nav-link item ${
                        activeComponent.type === "InformationSystem"
                          ? "active"
                          : ""
                      }`}
                      onClick={() => handleLink(`InformationSystem`)}
                    >
                      Information Systems
                    </a>
                 
                   
                  </div>
                </AccordionBody>
              </AccordionItem>

              <AccordionItem className="row">
                <AccordionHeader className="col-10 btn shadow-0 text-start text-transform-none text-white rounded-0 link-button">
                  <p
                    className={`nav-link link-text ${
                      activeComponent.type === "AdmissionPage" ? "active" : ""
                    }`}
                    onClick={() => handleLink(`AdmissionPage`)}
                  >
                    ADMISSION
                  </p>
                </AccordionHeader>
              </AccordionItem>

              <AccordionItem className="row">
                <AccordionHeader
                  className="col-10 btn shadow-0 text-start text-transform-none text-white rounded-0 link-button"
                  onClick={() => checkAriaExpanded("STUDENTSERVICES", refServ)}
                >
                  <p
                    ref={refServ}
                    className={`nav-link link-text ${
                      activeComponent.type === "StudentServicesPage" ||
                      activeComponent.type === "SchoolFacilities" ||
                      activeComponent.type === "ClubsOrganizations"
                        ? "active"
                        : ""
                    }`}
                  >
                    STUDENT SERVICES
                    <MenuDrop
                      name="STUDENTSERVICES"
                      expanded={ariaExpanded}
                      activeMenu={activeMenu}
                    />
                  </p>
                </AccordionHeader>

                <AccordionBody>
                  <div className="link-content ps-4 py-2 col-10">
                    <a
                      className={`nav-link item ${
                        activeComponent.type === "StudentServicesPage"
                          ? "active"
                          : ""
                      }`}
                      onClick={() => handleLink("StudentServicesPage")}
                    >
                      Scholarships & Linkages
                    </a>

                    <a
                      className={`nav-link item ${
                        activeComponent.type === "ClubsOrganizations"
                          ? "active"
                          : ""
                      }`}
                      onClick={() => handleLink("ClubsOrganizations")}
                    >
                      Clubs & Organizations
                    </a>
                    <a
                      className={`nav-link item ${
                        activeComponent.type === "SchoolFacilities"
                          ? "active"
                          : ""
                      }`}
                      onClick={() => handleLink("SchoolFacilities")}
                    >
                      School Facilities
                    </a>
                  </div>
                </AccordionBody>
              </AccordionItem>

              <AccordionItem className="row">
                <AccordionHeader
                  className="col-10 btn shadow-0 text-start text-transform-none text-white rounded-0 link-button"
                  onClick={() => checkAriaExpanded("ABOUT", refAbt)}
                >
                  <p
                    ref={refAbt}
                    className={`nav-link link-text ${
                      activeComponent.type === "AboutPage" ||
                      activeComponent.type === "BoardOfTrustees" 
                        ? "active"
                        : ""
                    }`}
                  >
                    ABOUT
                    <MenuDrop
                      name="ABOUT"
                      expanded={ariaExpanded}
                      activeMenu={activeMenu}
                    />
                  </p>
                </AccordionHeader>

                <AccordionBody>
                  <div className="link-content ps-4 py-2 col-10">
                    <a
                      className={`nav-link item ${
                        activeComponent.type === "AboutPage" ? "active" : ""
                      }`}
                      onClick={() => handleLink("AboutPage")}
                    >
                      Mindanao Kokusai Daigaku
                    </a>{" "}
                    <a
                      className={`nav-link item ${
                        activeComponent.type === "BoardOfTrustees"
                          ? "active"
                          : ""
                      }`}
                      onClick={() => handleLink("BoardOfTrustees")}
                    >
                      Board of Trustees
                    </a>{" "}
                   
                  </div>
                </AccordionBody>
              </AccordionItem>

              <AccordionItem className="row">
                <AccordionHeader
                  className="col-10 btn shadow-0 text-start text-transform-none text-white rounded-0 link-button"
                  onClick={() => checkAriaExpanded("MILESTONES", refMil)}
                >
                  <p
                    ref={refMil}
                    className={`nav-link link-text ${
                      activeComponent.type === "StudiesAndAwards" ||
                      activeComponent.type === "Milestones" ||
                      activeComponent.type === "SignificantVisits"
                        ? "active"
                        : ""
                    }`}
                  >
                    MILESTONES
                    <MenuDrop
                      name="MILESTONES"
                      expanded={ariaExpanded}
                      activeMenu={activeMenu}
                    />
                  </p>
                </AccordionHeader>

                <AccordionBody>
                  <div className="link-content ps-4 py-2 col-10">
                    <a
                      className={`nav-link item ${
                        activeComponent.type === "Milestones" ? "active" : ""
                      }`}
                      onClick={() => handleLink("Milestones")}
                    >
                      Testimonials
                    </a>{" "}
                    <a
                      className={`nav-link item ${
                        activeComponent.type === "StudiesAndAwards" ? "active" : ""
                      }`}
                      onClick={() => handleLink("StudiesAndAwards")}
                    >
                      Student Achievements
                    </a>{" "}
                    <a
                      className={`nav-link item ${
                        activeComponent.type === "SignificantVisits"
                          ? "active"
                          : ""
                      }`}
                      onClick={() => handleLink("SignificantVisits")}
                    >
                      Significant Visits
                    </a>
                  </div>
                </AccordionBody>
              </AccordionItem>

              <AccordionItem className="row">
                <AccordionHeader className="col-10 btn shadow-0 text-start text-transform-none text-white rounded-0 link-button">
                  <p
                    className={`nav-link link-text ${
                      activeComponent.type === "JLPT" ? "active" : ""
                    }`}
                    onClick={() => handleLink(`JLPT`)}
                  >
                    JLPT
                  </p>
                </AccordionHeader>
              </AccordionItem>

              <AccordionItem className="row">
                <AccordionHeader className="col-10 btn shadow-0 text-start text-transform-none text-white rounded-0 link-button">
                  <p
                    className={`nav-link link-text ${
                      activeComponent.type === "Contact" ? "active" : ""
                    }`}
                    onClick={() => handleLink(`Contact`)}
                  >
                    CONTACT
                  </p>
                </AccordionHeader>
              </AccordionItem>
            </Accordion>
          </div>
          <div className="position-absolute w-100 h-100 sidebar-logo-container overflow-hidden">
            <img className="sidebar-logo-overlay" src={logo} alt="" />
          </div>
        </div>
      </div>
    </>
  );
}
