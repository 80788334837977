import React, { useEffect, useState } from "react";

export default function BackToTop() {
  const [showButton, setShowButton] = useState(false);

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  const handleScroll = () => {
    if (window.pageYOffset > 300) {
      setShowButton(true);
    } else {
      setShowButton(false);
    }
  };

  const handleClick = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  };


  
  return (
    <div>
        <button
          type="button"
          className={`btn btn-secondary btn-lg ${showButton ? "active":""}`}
          id="btn-back-to-top"
          onClick={handleClick}
        >
          <i className="fas fa-arrow-up"></i>
        </button>
    </div>
  );
}

