import "./App.css";
import React, { Suspense, lazy, useEffect, useState, createContext, useContext } from "react";
import ToggleSidebar from "./Navigation/ToggleSidebar";
import Header from "./Header/Header";
import Footer from "./Header/Footer";
import AOS from "aos";
import NavigationTop from "./Navigation/NavigationTop";
import BackToTop from "./Functions/BackToTop";
import Cookies from "js-cookie";

const Homepage = lazy(() => import("./Home/Homepage"));
const HeroSection = lazy(() => import("./Home/HeroSection"));
const Contact = lazy(() => import("./Contact/Contact"));
const AcademePage = lazy(() => import("./Academics/AcademicsPage"));
const AdmissionPage = lazy(() => import("./Admission/AdmissionPage"));
const StudentServicesPage = lazy(() =>
  import("./StudentServices/StudentServicesPage")
);

const InternationalStudies = lazy(() =>
  import("./Academics/InternationalStudies")
);

const Education = lazy(() => import("./Academics/Education"));
const HumanServices = lazy(() => import("./Academics/HumanServices"));
const Entrepreneurship = lazy(() => import("./Academics/Entrepreneurship"));
const InformationSystem = lazy(() => import("./Academics/InformationSystem"));

const SchoolFacilities = lazy(() =>
  import("./StudentServices/SchoolFacilities")
);

const ClubsOrganizations = lazy(() =>
  import("./StudentServices/ClubsOrganizations")
);

const AboutPage = lazy(() => import("./About/AboutPage"));
const BoardOfTrustees = lazy(() => import("./About/BoardOfTrustees"));
const PresidentMessage = lazy(() => import("./About/PresidentMessage"));

const Milestones = lazy(() => import("./Milestones/Milestones"));
const JLPT = lazy(() => import("./Milestones/JLPT"));
const SignificantVisits = lazy(() => import("./Milestones/SignificantVisits"));
const StudiesAndAwards = lazy(() => import("./Milestones/StudiesAndAwards"));



const componentMap = {
  Homepage: Homepage,
  Contact: Contact,
  AcademePage: AcademePage,
  AdmissionPage: AdmissionPage,
  StudentServicesPage: StudentServicesPage,
  InternationalStudies: InternationalStudies,
  Education: Education,
  HumanServices: HumanServices,
  Entrepreneurship: Entrepreneurship,
  InformationSystem: InformationSystem,
  SchoolFacilities: SchoolFacilities,
  ClubsOrganizations: ClubsOrganizations,
  AboutPage: AboutPage,
  BoardOfTrustees: BoardOfTrustees,
  PresidentMessage: PresidentMessage,
  Milestones: Milestones,
  JLPT: JLPT,
  SignificantVisits: SignificantVisits,
  StudiesAndAwards: StudiesAndAwards,
};

export default function PageLayout() {

  const [activeComponentName, setHighlight] = useState(() => {
    const initialPage = Cookies.get("userPage");
    if (initialPage) {  
      return initialPage;
    } else {
      return 'Homepage';
    }
  });

  const [ActivePage, setActivePge] = useState(componentMap[activeComponentName]);
  const [ActivePageName, setActivePageName] = useState("Homepage");

 
  useEffect(() => {
    AOS.init({ once: true });

    if (ActivePageName === "Homepage") {
      window.scrollTo({ top: 0, behavior: "smooth" });
    }

    if (ActivePageName === "Homepage") {
      window.scrollTo({ top: 0, behavior: "smooth" });
    } else {
      window.scrollTo({ top: 280, behavior: "smooth" });
    }
  }, [ActivePageName]);

  const handlePageChange = (Component) => {
    setActivePge(componentMap[Component]);
    setActivePageName(Component);
  };

  const handleMoreButtonClick  = () => {
    handlePageChange('AboutPage');
  };


  return (
    <div className="App">
      <ToggleSidebar onPageChange={handlePageChange} />
      <Header />
      <NavigationTop onPageChange={(page) => handlePageChange(page)} />

      <div className="">
        <Suspense fallback={<Spinner />}>
          <div id="LoadPage">

          {ActivePageName === "Homepage" ? (
            <ActivePage onMoreButtonClick={handleMoreButtonClick} />
          ) : (
            <ActivePage />
          )}
          
          
          </div>
        </Suspense>
      </div>

      <BackToTop />
      <Footer />
    </div>
  );
}

function Spinner() {
  return (
    <div className="vh-100 d-flex justify-content-center align-items-center">
      <div className="spinner">
        <div className="dot1"></div>
        <div className="dot2"></div>
      </div>
    </div>
  );
}
