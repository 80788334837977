import React, { useState, useEffect } from "react";
import {
  AccordionBody,
  AccordionHeader,
  AccordionItem,
} from "react-headless-accordion";

export default function AccordItem(props) {
  return (
    <>
      <AccordionItem className="">
        <AccordionHeader className="btn shadow-2-strong text-transform-none  w-100 text-start mb-2 rounded-3 ">
          <div className="row d-flex justify-content-center align-items-center">
            <h5 className="fw-bold col-11 mt-3">{props.title}</h5>
            <AccordionBody className="mb-2 ">

              <div className="row d-flex justify-content-center align-items-center">

                <div className="px-5 pt-3 col-12">
                  
                  <p>{props.content}</p>
                </div>
              </div>
            </AccordionBody>
          </div>
        </AccordionHeader>
      </AccordionItem>
    </>
  );
}
